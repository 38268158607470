<template>
  <div class="bottom">
    <div>
      <img :style="{width: 168 * scale + 'px', height: 38 * scale + 'px'}" class="bg-logo" src="../assets/fanxinglogo.png" alt="">
      <img :style="{width: 734 * scale + 'px', height: 94 * scale + 'px'}" class="bg-title" src="../assets/title.png" alt="">
    </div>
    <div :style="{height: 50 * scale + 'px'}" class="time">
      <div>{{ day.format('YYYY/MM/DD HH:mm:ss').slice(0,17) }}</div>
      <div class="seconds">{{ day.format('YYYY/MM/DD HH:mm:ss').slice(17,18) }}</div>
      <div style="width: 25px;">{{ day.format('YYYY/MM/DD HH:mm:ss').slice(18,19) }}</div>
    </div>
    <carousel-3d
      v-if="logoList.length"
      :key="1"
      ref="Carousel3d"
      class="slide"
      display="7"
      :autoplay="true"
      :autoplay-timeout="12000"
      animation-speed="8000"
      inverse-scaling="0"
      border="0"
      perspective="0"
      :width="slideConf.width * scale"
      :height="slideConf.height * scale"
      :space="slideConf.space * scale"
      @before-slide-change="slideChange"
    >
      <slide v-for="(slide, key) in playList" :key="key" :index="key">
        <div style="width: 100%;height: 100%;margin: 0 auto;" @click="playAudio(slide)">
          <div class="right-logo">
            <img :src="slide.logo_img" style="width: 70px;height: 70px;border-radius: 50%;">
          </div>
          <img v-if="!slide.is_living" class="bg_img" :src="slide.bg_img" alt="">
          <video
            v-else
            :id="'video' + slide.aweme_id"
            :style="{width: 216 * scale + 'px', height: '80%', padding: `${10}px 0`, margin: '0 auto', display: 'block', boxSizing: 'border-box', backgroundImage: `url(${slide.bg_img})`, backgroundSize: '100%'}"
            autoplay
            muted
          />
          <p style="text-align: center" class="info-desc">{{ slide.brand }}</p>
          <img v-if="!slide.status" src="../assets/tag.png" class="warning" alt="">
        </div>
      </slide>
    </carousel-3d>
    <carousel-3d
      v-else
      :key="2"
      ref="Carousel3d"
      class="top slide"
      display="7"
      :autoplay="false"
      inverse-scaling="0"
      border="0"
      perspective="0"
      :width="slideConf.width * scale"
      :height="slideConf.height * scale"
      :space="slideConf.space * scale"
    >
      <slide v-for="(slide, key) in playList" :key="key" :index="key">
        <div style="width: 100%;height: 100%;margin: 0 auto;" @click="playAudio(slide)">
          <div class="right-logo">
            <img :src="slide.logo_img" style="width: 70px;height: 70px;border-radius: 50%;">
          </div>
          <img v-if="!slide.is_living" class="bg_img" :src="slide.bg_img" alt="">
          <video
            v-else
            :id="'video' + slide.aweme_id"
            :style="{width: 216 * scale + 'px', height: '80%', padding: `${10}px 0`, margin: '0 auto', display: 'block', boxSizing: 'border-box', backgroundImage: `url(${slide.bg_img})`, backgroundSize: '100%'}"
            autoplay
            muted
          />
          <p style="text-align: center" class="info-desc">{{ slide.brand }}</p>
          <img v-if="!slide.status" src="../assets/tag.png" class="warning" alt="">
        </div>
      </slide>
    </carousel-3d>
    <vue-seamless-scroll
      :data="dataList"
      :class-option="classOption"
      :style="{width: 1536 * (dataList.length < 9 ? dataList.length / 9 : 1) * scale + 'px', height: 100 * scale + 'px', marginTop: 34 * scale + 'px'}"
      class="warp"
      
    >
      <ul class="ul-item">
        <li v-for="(item, index) in logoList" :key="index" class="li-item">
          <img :src="item">
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import vueSeamlessScroll from 'vue-seamless-scroll'
import flvjs from 'flv.js';
import dayjs from 'dayjs';
export default {
  name: 'New',
  components: {
    Carousel3d,
    Slide,
    vueSeamlessScroll
  },
  data() {
    const indexList = [4, 5, 6, 7, 8, 0, 1, 2, 3];
    return {
      dataList: [],
      timer: null,
      time: null,
      refreshTime: null,
      play: true,
      playArray: [],
      classOption: {
        limitMoveNum: 2,
        direction: 3,
      },
      cacheList: [], // 缓存池
      playList: [], // 播放池
      unPlayList: [], // 未播列表
      liveList: [],
      listLength: 0,
      day: dayjs(),
      logoList: [],
      flag: true,
      indexList,
      slideConf: {
        width: 236,
        height: 460,
        space: 400
      },
      clientHeight: 0,
      scale: 1,
      num: 0,
      errorList: [
        {
          'aweme_id': '106644246',
          'aweme_name': '黄天鹅',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/huangtiane.png',
          'brand': '黄天鹅',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3.douyinpic.com/aweme/100x100/aweme-avatar/mosaic-legacy_31519000adbd52bf0b809.jpeg?from=4010531038',
          'total_user_str': '5323',
          'user_count_str': '6'
        },
        {
          'aweme_id': 'FILAFUSION',
          'aweme_name': 'FILAFUSION官方旗舰店',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/filafusion.png',
          'brand': 'FILAFUSION',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3-pc.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-0813_7e93d09df82a473b8d0b08b58b72ab62.jpeg?from=2956013662',
          'm3u8': {},
          'total_user_str': '',
          'user_count_str': ''
        },
        {
          'aweme_id': 'NBAStyleOfficial',
          'aweme_name': 'NBAStyle',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/nbastyle.png',
          'brand': 'NBA',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3-pc.douyinpic.com/aweme/100x100/aweme-avatar/mosaic-legacy_fba300047a0d227ee78c.jpeg?from=2956013662',
          'm3u8': {},
          'total_user_str': '',
          'user_count_str': ''
        },
        {
          'aweme_id': 'Huazhu_Vip',
          'aweme_name': '华住会',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/huazhuhui.png',
          'brand': '华住会',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3-pc.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-0813_cd24a0b463714fe88ac2957eb740591a.webp?from=2956013662',
          'm3u8': {},
          'total_user_str': '',
          'user_count_str': ''
        },
        {
          'aweme_id': 'NAN_INFINIPRO',
          'aweme_name': '能恩全护',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/quechao.jpeg',
          'brand': '雀巢',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_17a86073fe55dee4b6ff5404605e4373.jpeg?from=4010531038',
          'm3u8': {},
          'total_user_str': '2073',
          'user_count_str': '13'
        },
        {
          'aweme_id': '91906164702',
          'aweme_name': '强生安视优美瞳旗舰店',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/anshiyou.jpeg',
          'brand': '安视优',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3-pc.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_24288cb8ea78aaacebcc65949a5e389e.jpeg?from=2956013662',
          'm3u8': {},
          'total_user_str': '',
          'user_count_str': ''
        },
        {
          'aweme_id': 'Bellamysorganic',
          'aweme_name': '贝拉米Bellamy’s',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/beilami.jpeg',
          'brand': '贝拉米',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3.douyinpic.com/aweme/100x100/aweme-avatar/mosaic-legacy_1c9c00004a3c01d60c4a0.jpeg?from=4010531038',
          'm3u8': {},
          'total_user_str': '1929',
          'user_count_str': '8'
        },
        {
          'aweme_id': '49732933380',
          'aweme_name': '吉列博朗礼品屋',
          'bg_img': 'https://kscs-screen.oss-cn-hangzhou.aliyuncs.com/pic/jiliebolanglipinwu.png',
          'brand': '吉列博朗礼品屋',
          'flv': {},
          'is_living': 0,
          'logo_img': 'https://p3-pc.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_82b641f86fa6f662e0a67a9d402fe3d4.webp?from=2956013662',
          'm3u8': {},
          'total_user_str': '',
          'user_count_str': ''
        }
      ],
      params: {
        web_rid: null
      }
    }
  },
  watch: {
    dataList(val) {
      this.listLength = val?.filter(item => item.is_living).length;
      console.log('直播个数：' + this.listLength);
    },
    liveList: {
      handler: (newVal, oldVal) => {
        if ((newVal.length > 5 && oldVal.length <= 5) && oldVal.length || (newVal.length <= 5 && oldVal.length > 5)) {
          window.location.reload();
        }
      },
      immediate: false
    },
    clientHeight: {
      handler: function(newVal, oldVal) {
        this.scale = newVal / 832 > 1 ? 1 : newVal / 832;
      },
      immediate: false
    }
  },
  async mounted() {
    this.params = { ...this.$route.query }
    this.clientHeight = document.documentElement.clientHeight;
    await this.getDataList();
    this.time = setInterval(async () => {
      this.day = dayjs();
      this.clientHeight = document.documentElement.clientHeight;
      this.num++;
      if (this.num > 300) {
        const params = {
          "groupId": "21",
          "title": `大屏预警-${dayjs().format('YYYY.MM.DD HH:mm:ss')}`,
          "content": "[{\"text\":\"• 大屏轮播停止，请尽快排查\"}]",
          "msgType": 2,
          "templateId": 5,
          "linkUrl": "",
          "schedule": "",
          "appCode": 3 // appCode 3-代表消息中心
        }
        const result = await this.$request.fetch('/live/msg/group', params, 'post');
        if (result.data.errorCode === 0) {
          this.num = 0;
        }
      }
    }, 1000);
    await this.startPlay(this.playList);
    this.setTimer();
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    startPlay(data) {
      data.forEach((item, index) => {
        if (flvjs.isSupported() && item.is_living) {
          this.insertFlv(item, index);
        } else {
          console.log('flvjs不支持');
        }
      })
    },
    setTimer() {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          window.location.reload();
        }, 600000);
      }
    },
    async insertFlv(temp, index) {
      const videoElement = document.getElementById('video' + temp.aweme_id);
      this.player = flvjs.createPlayer({
        type: 'flv', // => 媒体类型 flv 或 mp4
        isLive: true, // => 是否为直播流
        hasAudio: true, // => 是否开启声音
        url: temp.flv ? temp.flv.SD1 : temp.flv_new.SD1, // => 视频流地址
      }, {
        autoCleanupSourceBuffer: true,
        autoCleanupMaxBackwardDuration: 30,
        autoCleanupMinBackwardDuration: 30
      });
      this.playArray.push({ id: temp.aweme_id, flv: this.player });
      try {
        if (videoElement) {
          this.player.attachMediaElement(videoElement); // => 绑DOM
          this.player.load();
          const playPromise = this.player.play();
          if (playPromise !== undefined) {
            playPromise.then(_ => {
            })
          }
          this.player.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
            if (errorType) {
              this.playList[index].is_living = 0;
            }
          });
        }
      } catch (error) {
        this.playList[index].is_living = 0;
        console.log(error, 'flv挂载失败');
      }
    },
    clearFlv(item) {
      if (item.flv) {
        item.flv.pause()
        item.flv.unload()
        item.flv.detachMediaElement()
        item.flv.destroy()
        item.flv = null;
      }
    },
    async slideChange(index) {
      this.num = 0;
      const flag = this.indexList[index];
      await this.updateLiveData(flag);
      this.isExistFlv()
      if (this.cacheList.length > 0) this.changeData(flag);
    },
    changeData(flag) {
      if (this.playList[flag].is_living) {
        const index = this.playArray.findIndex(item => item.id === this.playList[flag].aweme_id);
        this.clearFlv(this.playArray[index]);
        this.playArray.splice(index, 1);
        this.cacheList.push(this.playList[flag]);
      }
      if (this.cacheList.length > 0) {
        const temp = this.cacheList.shift();
        if (this.playList.findIndex(item => item.aweme_id === temp.aweme_id) === -1) {
          this.playList.splice(flag, 1, temp);
          this.$nextTick(() => {
            this.insertFlv(temp, flag);
          });
        }
      }
    },
    getDisplayAccount(data) {
      const list = []
      const unList = []
      const web_rids = this.params.web_rid.split(',').map(item => Number(item))
      data.forEach(item => {
        if (web_rids.indexOf(item.web_rid) > -1) {
          list.push({...item})
        } else {
          unList.push({...item})
        }
      })
      return { list, unList }
    },
    async getDataList() {
      const result = await this.$request.fetch('/api/flv_url', {}, 'get');
      if (result.status === 200) {
        /**
         * 判断当前链接是否有参数？
         * 若有，只赋值参数中存在的web_rid
         * 若没有, 按原有逻辑
         */
        if (!this.params.web_rid) {
          this.dataList = result.data.data; // 全量数据
          this.logoList = this.dataList.map(item => item.logo_img); // logo集合
          this.liveList = this.dataList.filter(item => item.is_living); // 在播集合
          this.unPlayList = this.dataList.filter(item => !item.is_living); // 未播集合
          if (this.liveList.length >= 9) {
            this.playList = this.liveList.slice(0, 9);
            this.cacheList = this.liveList.slice(9, this.liveList.length);
          } else {
            const stack = this.unPlayList.slice(0, 9 - this.liveList.length);
            this.unPlayList.splice(0, 9 - this.liveList.length);
            this.playList = [...stack, ...this.liveList];
          }
        } else {
          const displayObj = this.getDisplayAccount(result.data.data)
          this.dataList = displayObj.list
          this.logoList = this.dataList.map(item => item.logo_img); // logo集合
          this.liveList = this.dataList.filter(item => item.is_living); // 在播集合
          this.unPlayList = displayObj.unList; // 未播集合
          if (this.liveList.length >= 9) {
            this.playList = this.liveList.slice(0, 9);
            this.cacheList = this.liveList.slice(9, this.liveList.length);
          } else {
            // const stack = this.unPlayList.slice(0, 9 - this.liveList.length);
            // this.unPlayList.splice(0, 9 - this.liveList.length);
            this.playList = [...this.liveList];
          }
        }
      } else {
        this.playList = this.errorList;
        this.$refs.Carousel3d.setSize();
      }
    },
    async updateLiveData(flag) {
      const result = await this.$request.fetch('/api/flv_url', {}, 'get');
      const oldNotPlayList = this.unPlayList.map(item => item.brand);
      if (!this.params.web_rid) {
        this.liveList = result.data.data.filter(item => item.is_living);
        this.liveList.map(item => {
          if (oldNotPlayList.indexOf(item.brand) > -1) this.cacheList.push(item);
        })
        const temp = [...this.cacheList.map(item => item.brand), ...this.unPlayList.map(item => item.brand)];
        let playList = [];
        const cacheList = [];
        let unPlayList = [];
        result.data.data.map(item => {
          if (temp.indexOf(item.brand) === -1) playList.push(item);
          else cacheList.push(item);
        });
        unPlayList = this.playList.filter(item => !item.is_living).map(item => item.brand);
        playList.map(item => {
          unPlayList.map((data, index) => {
            if (item.brand === data && item.is_living && this.playList[flag].brand === item.brand) {
              this.clearFlv(this.playArray.filter(res => res.id === item.aweme_id)[0]);
              this.insertFlv(item, index);
            }
          })
        })
        playList = playList.filter(item => !item.is_living).map(item => item.brand);
        this.playList.map(item => {
          if (playList.indexOf(item.brand) !== -1) {
            item.is_living = 0;
            this.liveList.map(res => {
              if (res.aweme_id === item.aweme_id) res.is_living = 0;
            })
          }
        })
      } else {
        const displayObj = this.getDisplayAccount(result.data.data)
        this.dataList = displayObj.list
        this.logoList = this.dataList.map(item => item.logo_img); // logo集合
        this.liveList = this.dataList.filter(item => item.is_living); // 在播集合
        this.unPlayList = displayObj.unList; // 未播集合
        if (this.liveList.length >= 9) {
          this.playList = this.liveList.slice(0, 9);
          this.cacheList = this.liveList.slice(9, this.liveList.length);
        } else {
          // const stack = this.unPlayList.slice(0, 9 - this.liveList.length);
          // this.unPlayList.splice(0, 9 - this.liveList.length);
          this.playList = [...this.liveList];
        }
      }
    },
    // 音频播放
    playAudio(item) {
      this.playArray.map(data => {
        data.flv.muted = !(data.id === item.aweme_id && data.flv.muted);
      })
    },
    isExistFlv() {
      const videoDom = document.getElementsByClassName('right-3')[0].childNodes[0].childNodes[1];
      if (videoDom.className !== 'bg_img') {
        const videoId = videoDom.id.split('video')[1];
        const checkItem = this.playList.find(item => item.aweme_id === videoId);
        const videoIndex = this.playList.findIndex(item => item.aweme_id === videoId);
        if (checkItem.is_living && !videoDom.src) {
          this.insertFlv(checkItem, videoIndex);
        }
      }
    },
    refreshLiveData() {
      this.refreshTime = setInterval(() => {
        window.location.reload();
      }, 300000)
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: Rajdhani;
  src: url("../assets/font/Rajdhani-Bold.ttf");
}
@font-face {
  font-family: Alibaba;
  src: url("../assets/font/Alibaba-PuHuiTi-Medium.ttf");
}
.bg-logo{
  display: block;
  width: 168px;
  height: 38px;
  position: absolute;
  left: 72px;
  top: 34px;  // 之前34
}
.bg-title{
  width: 734px;
  height: 94px;
  display: block;
  margin: 0px auto 0; // 0 auto
}
.time{
  height: 50px;
  color: white;
  font-family: Rajdhani;
  font-size: 43px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  .seconds{
    width: 25px;
  }
}
.carousel-3d-slide .right-logo{
  display: block;
  position: absolute;
  width: 86px;
  height: 86px;
  top: -36px;
  left: -28px;
  background-image: url('../assets/zhibologo-bg.png');
  padding: 8px;
  box-sizing: border-box;
  z-index: 9999;
}
.info-desc{
  font-family: Alibaba;
  font-size: 28px;
  margin-top: 18px;
  margin-bottom: 34px;
  background-image:-webkit-linear-gradient(top,#fff,#838cf7);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
}
.box {
  display: flex;
  justify-content: space-around;
}
.box .box-item {
  width: 14%;
}
.bottom {
  height: 100vh;
  width: 100%;
  /*// box-sizing: border-box;*/
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.bottom:hover .swiper-button-prev{
  display: block;
}

.bottom:hover .swiper-button-next{
  display: block;
}
.carousel-3d-container{
  overflow:visible !important;
}
.carousel-3d-slide {
  overflow:visible;
  background-color: rgba(0,0,0,0);
  color: white;
  //position: absolute;
}
.carousel-3d-slide video{
  //background: url("../assets/card_bg.jpeg") 100% ;
  margin: 0 auto;
}
.left1 {
  width: 205px;
  height: 364px;
}
.bg_img{
  display: block;
  width: 950%;
  height: 80%;
}
.top .carousel-3d-slide{
  opacity: 1;
  visibility: visible !important;
}
.warp {
  width: 1536px;
  height: 100px;
  margin: 34px auto 0;
  overflow: hidden;
  margin: 0 auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 86px;
        height: 86px;
        margin-right: 10px;
        line-height: 86px;
        color: #fff;
        text-align: center;
        font-size: 30px;
        position: relative;
        background-image: url('../assets/dogntai.gif');
        img{
          width: 70px;
          height: 70px;
          border-radius: 50%;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }
    }
  }
}
.warning{
  display: block;
  width: 30% !important;
  margin: -26px auto;
}

</style>
